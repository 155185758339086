(function($) {
  'use strict';

  var snapper = new Snap({
    element: document.querySelector('[data-snap__content]'),
    dragger: document.querySelector('[data-snap__dragger]'),
    disable: 'left'
  });

  snapper.disable();

  $(document).on('click.snaptoggle', '[data-snap__toggle]', function() {
      var data = snapper.state();
      if (data.state === 'closed') {
        snapper.open('right');
      } else  {
        snapper.close();
      }
    });

  $(window).on('resize.snap', function() {
    if (window.snap__timeout) {
      clearTimeout(window.snap__timeout);
    }

    window.snap__timeout = setTimeout(function() {
      var data = snapper.state();
      var width = $(window).width();
      var threshold = 768;

      if (width > threshold && (data.state === 'left' || data.state === 'right')) {
        snapper.close();
        snapper.disable();
      } else if (width <= threshold) {
        snapper.enable();
      }
    }, 10);
  });
  $(window).trigger('resize.snap');
})(jQuery);